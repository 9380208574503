const Swiper = require('swiper/swiper-bundle.min.js');

function setTiles(container) {
    let url = container.dataset.source;
    let isCarousel = container.classList.contains('ig-gallery--carousel');
    fetch(url)
        .then(response => response.json())
        .then(data => {
            let post = data.data,
                icon = ``;
            // console.log(post);
            for (let p = 0; p < post.length; p++) {
                let user = post[p].username,
                    id = post[p].id,
                    type = post[p].media_type,
                    link = post[p].permalink,
                    media = post[p].media_url,
                    caption = post[p].caption || '',
                    thumb = type === "VIDEO" ? post[p].thumbnail_url : null;

                if (type === "CAROUSEL_ALBUM") {
                    icon = `<span class="ig-tile-icon"><i class="fa fa-clone"></i></span>`;
                } else if (type === "VIDEO") {
                    icon = `<span class="ig-tile-icon"><i class="fa fa-film"></i></span>`;
                } else {
                    icon = ``;
                }

                container.innerHTML += `<span class="ig-tile ig-tile--${type} ${caption.length > 0 ? 'hasCaption' : ''}" data-id="${id}" data-user="${user}">${icon}<a class="ig-tile-link" href="${link}" target="_blank"><img class="ig-tile-image lazyload" src="${thumb !== null ? thumb : media}" alt="${user} - Image ${id}"/><div class="ig-tile-caption">${caption}</div></a></span>`;
            }
            // Initialize carousel component(s)
            if (isCarousel) {
                initCarousel(container);
            }
        });
}

function initCarousel(container) {
    let componentId = container.dataset.componentId;

    /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "instaCarousel" }]*/
    const instaCarousel = new Swiper(container, {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 1,
        centerInsufficientSlides: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            enabled: false,
            clickable: true
        },
        navigation: {
            nextEl: `.swiper-nav-next-${componentId}`,
            prevEl: `.swiper-nav-prev-${componentId}`
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: false
        },
        breakpoints: {
            480: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 4
            }
        }
    });
}

module.exports = function () {
    $(document).ready(function () {
        let ig = $('.ig-gallery');

        for (let i = 0; i < ig.length; i++) {
            setTiles(ig[i]);
        }
    });
};
