'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $('body').addClass('in-editor');
    }
    processInclude(require('./experience/spacer'));
    processInclude(require('./components/instagram'));
});
